import React from 'react';
import { Skeleton, SkeletonBlock, SkeletonContent } from '@one-thd/sui-atomic-components';

export function ProHomePageLoadingLayout() {

  const loadingLayoutGrid = [
    'sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-3',
    'sui-gap-8 md:sui-gap-y-10 md:sui-gap-x-5'
  ].join(' ');

  return (
    <div className={loadingLayoutGrid}>
      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="wide" height="unset" />
        </Skeleton>
      </div>

      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="wide" height="unset" />
        </Skeleton>
      </div>

      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="wide" height="unset" />
        </Skeleton>
      </div>

      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full ">
          <SkeletonContent disablePadding className="sui-gap-0" initialSize="full">
            <SkeletonBlock disablePadding aspect="wide" height="unset" />
            <SkeletonBlock disablePadding aspect="wide" height="unset" />
          </SkeletonContent>
        </Skeleton>
      </div>

      <div className="sui-mr-2 sui-col-span-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="wide" height="unset" />
        </Skeleton>
      </div>

      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="square" height="unset" />
        </Skeleton>
      </div>

      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="square" height="unset" />
        </Skeleton>
      </div>

      <div className="sui-mr-2">
        <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
          <SkeletonBlock aspect="square" height="unset" />
        </Skeleton>
      </div>
    </div>
  );
}
