import React from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { useStore } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { ProHomepage } from './ProHomepage';

export const ProHomepageContainer = ({ slug, thdSEOBotDetection }) => {

  const { isCustomerIdentified = false } = useThdCustomer();
  const store = useStore();
  const { isLocalized } = store;

  const hpDefaultVariables = {
    isBrandPricingPolicyCompliant: isLocalized ? isCustomerIdentified : false
  };

  return (
    <ErrorBoundary name="my-homepage-content">
      <QueryProvider
        cacheKey="pro-homepage"
        defaultVariables={hpDefaultVariables}
      >
        <ProHomepage slug={slug} thdSEOBotDetection={thdSEOBotDetection} />
      </QueryProvider>
    </ErrorBoundary>
  );
};

ProHomepageContainer.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};

ProHomepageContainer.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};
