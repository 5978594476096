export const componentOptions = {
  LoyaltyBenefits: {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 3,
        max: 3,
      },
    },
  },
  EventCountdownTimer: {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 12,
        max: 12,
      },
    },
  },
  'Top Savings for You': {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 8,
        max: 8,
      },
    },
  },
  SpecialBuyOfTheDay: {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 4,
        max: 4,
      },
    },
  },
  'Recent Purchases': {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 9,
        max: 12,
      },
    },
  },
  'Todays Recommendations for You': {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 8,
        max: 12,
      },
    },
  },
};